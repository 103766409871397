import React from "react";
import PropTypes from "prop-types";
import "./Sponsor.scss";

const Sponsor = ({ graphics }) => {
  // Ako nema grafike za footer, ne prikazuj komponentu
  if (!graphics?.footerText && !graphics?.footerLogoUrl) {
    return null;
  }

  // Funkcija koja proverava da li je footerText validan URL
  const isValidUrl = (text) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // podržava http ili https
      "((([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})|" + // domen
      "localhost|" + // lokalno hostovanje
      "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})" + // IP adresa
      "(\\:\\d+)?(\\/.*)?$", // port i putanja
      "i"
    );
    return urlPattern.test(text);
  };

  // Funkcija za dobijanje samo domena iz URL-a
  const getDomainFromUrl = (url) => {
    return new URL(url).hostname.replace("www.", "");
  };

  return (
    <div className="sponsor-wrapper">
      {graphics.footerText && isValidUrl(graphics.footerText) ? (
        <a href={graphics.footerText} target="_blank" rel="noopener noreferrer">
          {getDomainFromUrl(graphics.footerText)}
        </a>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: graphics.footerText }}></span>
      )}
      {graphics.footerLogoUrl && (
        <img src={graphics.footerLogoUrl} alt="sponsor logo" />
      )}
    </div>
  );
};

Sponsor.propTypes = {
  graphics: PropTypes.object,
};

export default Sponsor;
