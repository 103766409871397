import { Box } from "@mui/material";
import styled, { css, keyframes } from "styled-components";
import MusicDisc from "../MusicDisc/MusicDisc";
import { ReactComponent as XIcons } from "../../assets/images/svg/circle-command-x-colored.svg";

const livesAnimation = keyframes`
  0%{
    transform: scale(0);
  }
  20%{
    transform: scale(1.5);
  }
  40%{
    transform: scale(0.5);
  }
  60%{
    transform: scale(1.25);
  }
  80%{
    transform: scale(0.75);
  }
  100%{
    transform: scale(1);
  }
`;

export const PlayerLivesContainer = styled(Box)`
  position: relative;
`;
export const PlayerLivesLine = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 4.5px;
  margin-bottom: 5px;
  /* height: 50px; */
  @media (max-width: 600px) {
    /* height: 50px; */
    gap: 4px;
  }
`;
export const SinglePlayerLive = styled(Box)`
  width: calc(400px / 100 * 2);
  @media (max-width: 600px) {
    width: 2vw;
  }
`;
export const LifeDisc = styled(MusicDisc)`
  width: 100%;
  height: 100%;
`;
export const GameOverLives = styled("p")`
  display: flex;
  justify-content: center;  // Center the content
  align-items: center;      // Vertically align the timer elements
  width: 100%;
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: -10%;
  ${(props) =>
    props?.$gameOver &&
    css`
      animation: ${livesAnimation} 1.7s ease-in-out;
    `}
  @media screen and (max-width: 600px) {
    top: 0;
  }
`;

// export const SecContainer = styled("span")`
//   display: inline-block;
//   width: 49%;
//   text-align: right;
//   @media (min-width: 600px) and (max-width: 1349px) {
//     margin-top: 5px;
//   }
//   @media screen and (min-width: 1350px) {
//     margin-bottom: 6px !important;
//   }
// `;
export const SecContainer = styled("span")`
  display: inline-flex;
  justify-content: center;
  align-items: baseline; // Align digits at the baseline
  text-align: center;
  font-size: 3.8rem; // Adjust as needed to match your design
  @media (min-width: 600px) and (max-width: 1349px) {
    margin-top: 5px;
  }
  @media screen and (min-width: 1350px) {
    margin-bottom: 6px !important;
  }
`;

export const XIcon = styled(XIcons)`
  display: block;
  margin: -5px auto;
  width: 100px;
  height: 100px;
`;
