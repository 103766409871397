import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import {
  fetchGameError,
  fetchGameSuccess,
  fetchGuessLinkError,
  fetchGuessLinkSuccess,
  fetchGuessSongError,
  fetchGuessSongSuccess,
  fetchListLinksError,
  fetchListLinksSuccess,
  fetchListSongsError,
  fetchListSongsSuccess,
  setGame,
  setGuessLink,
  setGuessSong,
  setListLinks,
  setListSongs,
} from "../actions/game/gameActions";
import {
  attemptFetchAllGames,
  attemptFetchLinks,
  attemptFetchSingleGame,
  attemptFetchSongs,
  attemptPostGameAnswer,
  attemptPostSongAnswer,
} from "../../request/gameRequest";
import {
  GAME_FETCH,
  GUESS_LINK_FETCH,
  GUESS_SONG_FETCH,
  LIST_LINKS_FETCH,
  LIST_SONGS_FETCH,
} from "../actions/game/gameActionConstants";

// function* fetchGame({ payload }) {
//   try {
//     const allGames = yield call(attemptFetchAllGames); // ==>  This is for production
//     const singleGame = yield call(attemptFetchSingleGame, allGames.data?.id); //---------------------trajno resenje
//     // const singleGame = yield call(
//     //   attemptFetchSingleGame,
//     //   "8d166b01-82f8-443a-8d96-4e1ebe9ab114"
//     // ); //privremeno resenje dok se ne ispravi bekend
//     singleGame?.data.songs.sort(function (a, b) {
//       return a.orderNumber - b.orderNumber;
//     }); //sorting songs by orderNumber!!!
//     yield put(setGame(singleGame?.data));
//     yield put(fetchGameSuccess());
//     if (payload.handleApiResponseSuccess) {
//       yield call(payload.handleApiResponseSuccess);
//     }
//   } catch (e) {
//     if (e.response && e.response.data) {
//       const errorMessage = yield call(rejectErrorCodeHelper, e);
//       yield put(fetchGameError(errorMessage));
//     }
//   }
// }

function* fetchGame({ payload }) {
  try {
    // Formiramo današnji datum
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

    console.log("Formatted date for fetching game:", formattedDate);

    const directoryName = payload?.directoryName;

    // Ako nema `directoryName`, pozivamo backend samo sa `date` parametrom
    const singleGame = directoryName
      ? yield call(attemptFetchSingleGame, { directoryName, date: formattedDate })
      : yield call(attemptFetchSingleGame, { date: formattedDate });

    console.log("Fetched single game data:", singleGame);

    // Ako dobijemo niz igara, obrađujemo sve igre
    let gameDataArray = Array.isArray(singleGame.data)
      ? singleGame.data
      : [singleGame.data]; // Ako je samo jedan kviz, pravimo niz

    gameDataArray.sort((a, b) => {
      if (a.order === null && b.order === null) return 0;
      if (a.order === null) return 1;
      if (b.order === null) return -1;
      return a.order - b.order;
    });

    // Ako nema `directoryName`, filtriramo igre bez `directory` ili sa praznim `directory` nizom
    if (!directoryName || directoryName.length === 0) {
      gameDataArray = gameDataArray.filter((game) => game.directory?.length === 0);
      console.log("Filtered games without directory:", gameDataArray);
    }

    // Sortiramo pesme za svaki kviz
    gameDataArray.forEach((gameData) => {
      if (gameData?.songs?.length > 0) {
        gameData.songs.sort((a, b) => a.orderNumber - b.orderNumber);
        console.log(`Sorted songs for game ${gameData.id}:`, gameData.songs);
      } else {
        console.log(`No songs found for the game ${gameData.id}.`);
      }
    });

    // Smeštanje svih kvizova u Redux store kao niz
    yield put(setGame(gameDataArray));
    console.log("All games data stored in Redux:", gameDataArray);

    // Poziv akcije fetchGameSuccess
    yield put(fetchGameSuccess());

    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (e) {
    console.log("Error occurred during game fetching:", e);
    const errorMessage = yield call(rejectErrorCodeHelper, e);
    yield put(fetchGameError(errorMessage));
  }
}


function* fetchSongs({ payload }) {
  try {
    const queryString = new URLSearchParams();
    queryString.set("pageNumber", 1);
    queryString.set("pageSize", 200);
    queryString.set("searchTerm", payload?.searchTerm);
    const songs = yield call(attemptFetchSongs, queryString);

    // Log the songs to the console
    if (songs.data.indexOf("U Can’t Touch This - MC Hammer") !== -1) {
      //bag from backend send two titles of same song and here I splice first if exists in array
      songs.data.splice(
        songs.data.indexOf("U Can’t Touch This - MC Hammer"),
        1
      );
    }
    yield put(setListSongs(songs?.data));
    yield put(fetchListSongsSuccess());
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, e);
      yield put(fetchListSongsError(errorMessage));
    }
  }
}

function* fetchLinks({ payload }) {
  try {
    const queryString = new URLSearchParams();
    queryString.set("pageNumber", 1);
    queryString.set("pageSize", 100);
    queryString.set("searchTerm", payload?.searchTerm);
    const links = yield call(attemptFetchLinks, queryString);
    yield put(setListLinks(links?.data));
    yield put(fetchListLinksSuccess());
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, e);
      yield put(fetchListLinksError(errorMessage));
    }
  }
}

function* fetchGuessSong({ payload }) {
  try {
    const guessedSongResult = yield call(attemptPostSongAnswer, {
      answer: {
        answer: payload?.answer,
      },
      id: payload?.id,
    });
    yield put(setGuessSong(guessedSongResult?.data));
    yield put(fetchGuessSongSuccess());
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, e);
      yield put(fetchGuessSongError(errorMessage));
    }
  }
}

function* fetchGuessLink({ payload }) {
  try {
    const guessedLinkResult = yield call(attemptPostGameAnswer, {
      answer: {
        answer: payload?.answer,
      },
      id: payload?.id,
    });
    yield put(setGuessLink(guessedLinkResult?.data));
    yield put(fetchGuessLinkSuccess());
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, e);
      yield put(fetchGuessLinkError(errorMessage));
    }
  }
}

export default function* gameSaga() {
  yield all([
    takeLatest(GAME_FETCH, fetchGame),
    takeLatest(LIST_SONGS_FETCH, fetchSongs),
    takeLatest(LIST_LINKS_FETCH, fetchLinks),
    takeLatest(GUESS_LINK_FETCH, fetchGuessLink),
    takeLatest(GUESS_SONG_FETCH, fetchGuessSong),
  ]);
}
