import { getRequest, postRequest, replaceInUrl } from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchAllGames = () => {
  return getRequest(replaceInUrl(apiEndpoints.game.getAllGames));
};

// export const attemptFetchSingleGame = (payload) => {
//   return getRequest(
//     replaceInUrl(apiEndpoints.game.getSingleGame, {
//       id: payload,
//     })
//   );
// };

export const attemptFetchSingleGame = (payload) => {
  const { directoryName, date } = payload;

  const url = directoryName
    ? `${replaceInUrl(apiEndpoints.game.getSingleGame, {
        id: "directory",
      })}?date=${date}&directory=${directoryName}`
    : `${replaceInUrl(apiEndpoints.game.getSingleGame, {
        id: "directory",
      })}?date=${date}`;

  console.log("Constructed URL for game fetch:", url);

  return getRequest(url);
};

export const attemptPostGameAnswer = (payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.game.postGameAnswer, {
      id: payload?.id,
    }),
    payload.answer
  );

export const attemptPostSongAnswer = (payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.game.postQuestionAnswer, {
      id: payload?.id,
    }),
    payload.answer
  );

export const attemptFetchSongs = (payload) =>
  getRequest(apiEndpoints.game.getSongs, payload);
//getSongs: "list/songs",


export const attemptFetchLinks = (payload) =>
  getRequest(apiEndpoints.game.getLinks, payload);
